.Card {
    max-width: 755px;
    padding: 30px 40px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: center;
    border-radius: 5px;
    margin: 0 50px;

    .profilImg {
        position: relative;
        width: 200px;
        height: 200px;
        margin: 0 auto 30px auto;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: $color-1;
            border-radius: 50%;
            left: 10px;
            bottom: 0;
        }

        .img {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .quote {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px;
            border-radius: 50%;
            font-size: 20px;
            color: #fff;
            background-color: $color-1;
            position: absolute;
            top: 10px;
            left: 0;
        }
    }

    h4 {
        color: $color-2;
        font-size: 20px;
        letter-spacing: .1rem;
    }

    small {
        line-height: 25px;
        text-transform: uppercase;
        color: $color-1;
        font-weight: 400;
        font-size: 16px;
    }

    p {
        margin-top: 10px;
        line-height: 30px;
        color: #617d98;
        font-size: 20px;
    }

    .navigation {
        margin-top: 25px;

        svg {
            margin: 0 10px;
            transition: all .2s ease-in-out;
            cursor: pointer;
            color: #48a6e9aa;
            font-size: 28px;

            &:hover {
                color: $color-1;
                transform: scale(1.1);
            }
        }
    }

    button {
        margin-top: 20px;
        padding: 10px;
        border: none;
        border-radius: 5px;
        font-weight: 700;
        font-size: 16px;
        background-color: #ebf7ff;
        color: $color-1;
    }
}

@media screen and (max-width: 415px) {
    .Card {
        width: 95%;
        margin: 0;

        .profilImg {
            width: 100px;
            height: 100px;

            .quote {
                font-size: 10px;
                padding: 10px;
                left: -5px;
            }
        }

        h4, p {
            font-size: 16px;
        }
        small {
            font-size: 14px;
        }
    }
}