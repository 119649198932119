.App {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        position: relative;
        font-size: 50px;
        color: $color-2;
        margin-bottom: 75px;

        &::after {
            content: '';
            position: absolute;
            width: 50%;
            height: 6px;
            background-color: $color-1;
            left: calc(50% - 70px);
            bottom: -15px;
        }
    }
}

@media screen and (max-width: 415px) {
    .App {
        h1 {
            margin: 20px 0 40px 0;
        }
    }
}